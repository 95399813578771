.center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.links {
  color: white;
  text-decoration: none;
}

.cards {
  position: relative;
  width: 100%;
  justify-content: center;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 10px;
  color: black;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.main {
  padding-top: 100px;
  color: white;
}

h1 {
  padding-bottom: 50px;
  padding-top: 59px;
}